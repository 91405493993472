<template>
  <section class="container">
    <h3 class="pb-4">Autoridades del Ministerio de Minería</h3>
    <h4>Ministro de Minería</h4>
    <p>Juan Pablo Perea Fontivero</p>
    <h4>Secretaría Técnica</h4>
    <ul>
      <li>Secretaria Técnica: Lic. Natalia Marchese</li>
      <li>Consejo Minero: Ing. Eduardo Balmaceda y Dr. Ricardo Quiroga</li>
      <li>Dirección de Registro Minero y Catastro: Ing. Pablo Fernández</li>
      <li>Dirección de Despacho Minero: Roberto Villejas</li>
      <li>Escribanía de Minas: Fernanda Llarenas</li>
    </ul>
    <h4>Ministro de Minería</h4>
    <ul>
      <li>Secretario: Ing. Denis Monardez</li>
      <li>Centro de Investigación para la Prevención de la Coordinación Ambiental: Ing. Graciela Arredondo.</li>
      <li>Dirección de Fiscalización Ambiental y Control Minero: Lic. Roberto Luna.</li>
      <li>Dirección de Evaluación Ambiental Minera: Lic. Roberto Moreno.</li>
      <li>Coordinador de Mina Veladero y Pascua Lama: Ing. Guillermo Serafini.</li>
      <li>Coordinador de Mina Gualcamayo y Casposo: Ing. Gabriel Cardinali. </li>
    </ul>
    <h4>Secretaría de Desarrollo Minero Sustentable</h4>
    <ul>
      <li>Secretario: CP Guillermo Olguín</li>
      <li>Dirección de Desarrollo Minero Sustentable: Mariana Azcona</li>
      <li>Dirección de Fiscalización de Ingresos: Jésica Cabrera</li>
      <li>Subsecretaria de Coordinación Administrativa: Lucía Maurín</li>
      <li>Contable:  Julia Imazio</li>
      <li>Despacho Administrativo: Graciela Gómez</li>
      <li>Recursos Humanos: Nicolás Mercado</li>
    </ul>
    <p><b>Dirección de Promoción, Difusión y Comunicación Minera:</b> Lic. Angeles Irusta</p>
    <p><b>Instituto Provincial de Exploraciones y Explotaciones Mineras (IPEEM):</b></p>
    <p>Presidente: Ing. Eduardo Machuca</p>
    <p>Vicepresidente: Ing. Ricardo Gutiérrez</p>

  </section>  
</template>

<script>
import d from "../../data.json";

export default {
  name: "OrganigramaComponent",
  props: {
    titulo: String,
    subtitulo: String,
    columnas: String,
    filtros: Object,
  },
  data() {
    return {
      items: null,
      dataFile: Object,
    };
  },
  mounted() {
    this.dataFile = d;
    console.log(this.dataFile);
  },
  created() {
    this.items = this.dataFile.items;
  },
};
</script>

<template>
  <section>
    <div class="container">
      <h2 class="pb-2">
        {{ titulo != null ? titulo : this.title != null ? this.title : "" }}
      </h2>
      <div
        v-if="!items"
        class="row row-cols-1 g-4"
        v-bind:class="[
          columnas > 0 ? 'row-cols-md-' + columnas : 'row-cols-md-3',
        ]"
      >
        <div
          class="col"
          v-for="n in parseInt(columnas) > 0 ? parseInt(columnas) : 3"
          v-bind:key="n"
        >

        </div>
      </div>
      <div
        class="row row-cols-1 g-4"
        v-bind:class="[
          columnas > 0 ? 'row-cols-md-' + columnas : 'row-cols-md-3',
        ]"
      >
        <div class="col" v-for="(el, i) in dataFile" :key="i">
          <div class="card">
            <div class="card-body rounded d-flex align-items-center">
              <div
                class="icon-square d-inline-flex align-items-center justify-content-center fs-4 flex-shrink-0 me-3"
              >
                <svg
                  v-if="el.icon.includes('ptn')"
                  class="ptn-i-2x text-ptn-color-gsj-6"
                  v-bind:class="[el.desc ? 'ptn-i-2x' : '']"
                >
                  <use v-bind="{ 'xlink:href': '#' + el.icon }"></use>
                </svg>
                <span
                  v-if="!el.icon.includes('ptn')"
                  class="material-symbols-outlined text-ptn-color-gsj-6 ptn-i-2x"
                  v-bind:class="[el.desc ? 'ptn-i-2x' : '']"
                  >{{ el.icon }}</span
                >
              </div>
              <div>
                <div class="card-title" v-bind:class="[el.desc ? 'h4' : 'h4']">
                  <a
                    v-bind:href="el.url ? el.url : '#'"
                    v-bind:target="el.target ? el.target : '_self'"
                    class="stretched-link link-secondary text-ptn-color-gsj-5"
                    >{{ el.title }}</a
                  >
                </div>
                <p class="card-text" v-if="el.desc">{{ el.desc }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import d from "../../data.json";

export default {
  name: "NormasLegalesComponent",
  props: {
    titulo: String,
    subtitulo: String,
    columnas: String,
    filtros: Object,
  },
  data() {
    return {
      items: null,
      dataFile: Object,
    };
  },
  mounted() {
    this.dataFile = d;
    console.log(this.dataFile);
  },
  created() {
    this.items = this.dataFile.items;
  },
};
</script>

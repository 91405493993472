<script>
import DocumentListComponent from "./components/resources/DocumentListComponent.vue";
import AutoriryComponent from "./components/resources/AutoriryComponent.vue";
import NewsComponent from "./components/resources/NewsComponent.vue";
import ITCGridComponent from "./components/resources/ITCGridComponent.vue";
import BoxIconGridComponent from "./components/resources/BoxIconGridComponent.vue";
import MultiSliderComponent from "./components/resources/MultiSliderComponent.vue";
import FrecuentAccessComponent from "./components/resources/FrecuentAccessComponent.vue";
import GridLinkComponent from "./components/resources/GridLinkComponent.vue";
import CardGridComponent from "./components/resources/CardGridComponent.vue";
export default {
  name: "HomeView",
  components: {
    /* eslint-disable */
    DocumentListComponent,
    /* eslint-disable */
    AutoriryComponent,
    /* eslint-disable */
    NewsComponent,
    /* eslint-disable */
    ITCGridComponent,
    /* eslint-disable */
    BoxIconGridComponent,
    /* eslint-disable */
    MultiSliderComponent,
    /* eslint-disable */
    FrecuentAccessComponent,
    /* eslint-disable */
    GridLinkComponent,
    /* eslint-disable */
    CardGridComponent,
  },
  data() {
    return {
      site: 12,
    };
  },
  mounted() {
    document.title = process.env.VUE_APP_TITLE;
  },
};
</script>

<template>
  <CardGridComponent
    columnas="4"
    :data="[
      {
        target: '_self',
        title: 'Normas Legales',
        url: '?uid=CUMV-VIEW-NormasLegalesComponent',
      },
      {
        target: '_blank',
        title: 'Catastro Minero Digital',
        url: 'https://datosabiertos.sanjuan.gob.ar/organization/ministerio-de-mineria',
      },
      {
        target: '_self',
        title: 'Mapa Minería Metalífera',
        url: 'https://mining.sanjuan.gob.ar/?uid=mapainteractivo',
      },
      {
        target: '_self',
        title: 'Organigrama ',
        url: '?uid=CUMV-VIEW-OrganigramaComponent',
      },
    ]"
  />

  <ITCGridComponent
    :columnas="3"
    titulo="Consultas y Trámites"
    :data="[
      {
        desc: null,
        icon: 'ptn-i-documento',
        target: '_self',
        title: 'Consulta de expedientes',
        url: '?uid=CUMV-VIEW-ExpedientesComponent',
      },
      {
        desc: null,
        icon: 'question_answer',
        target: '_self',
        title: 'Consultas y solicitudes',
        url: '?uid=CUMV-VIEW-FormComponent',
      },
      {
        desc: null,
        icon: 'map',
        target: '_blank',
        title: 'San Juan Mining',
        url: '../../pdf/FOLLETO_MINERIA_PRESENTE_Y_FUTURO_SAN_JUAN_ENG-nuevo.pdf',
      },
    ]"
  />

  <NewsComponent titulo="Noticias" />

  <CardGridComponent
    columnas="1"
    :data="[
      {
        target: '_self',
        title: 'Licitaciones',
        url: 'https://licitaciones.sanjuan.gob.ar/',
      },
    ]"
  />

  <!--   <DocumentListComponent
    resultados="5"
    :show-fecha="false"
    titulo="Guía de Trámites"
    :filtros="{ site: this.site }"
  /> -->

  <!-- <BoxIconGridComponent titulo="Recursos" :filtros="{ 'site': this.site }"  /> -->

  <!--  <BoxIconGridComponent titulo="¿Cómo me contacto?" :filtros="{ 'site': 13 }" :iconosGrandes="true"  />
 -->
  <AutoriryComponent
    :showProtocolo="false"
    :showLinks="true"
    :data="[
      {
        title: 'Juan Pablo Perea',
        subtitle: 'Ministro de Minería',
        imagen: require('@/assets/img/mindemineria.jpg'),
      },
    ]"
  />
</template>
